import ProductFlowRoutes from "@surecomp/product-flow/routes.const";
import { Routes as DokaRoutes } from "@surecomp/doka-flow/constants/route";
import { Routes as ReportsRoutes } from "@surecomp/reports-ui/constants/route";
import { isEnableNewReport } from "./helpers/featureFlag";

export const URL_LANG_KEY = "lang";

const Routes = {
	APP_ABOUT_JSON: "/about",
	AUDITLOGS: "/auditLogs",
	PRODUCT: "/product",
	STANDBY_LC: "/standbyLc",
	PARTIES: "/parties",
	PARTY_EDIT: "/parties/edit/:partyId",
	CUSTOM_FIELDS: "/customFields",
	RULES: "/dRules",
	EXPORT_LC: "/exportLc",
	IMPORT_LC: "/importLc",
	EXTENDED: "/extended",
	EXTENDED_STANDBY_LC: "/extendedStandByLc",
	BANKS: "/banks",
	BANK_EDIT: "/banks/:bankId",
	REPORTS: "/reports",
	EMBEDED_REPORT: "/reports/:id/:datasetId",
	ADD_BANKS: "/addBank",
	BANK_LIMIT: "/bankLimit",
	LIMIT_DETAILS: "/banks/limitDetails/:orgId/bank/:bankOrgId",
	CURRENCIES: "/currencies",
	ORGANIZATION_PROFILE: "/organizationProfile",
	ORGANIZATION_STRUCTURE: "/organizationStructure",
	STANDARD_TEXT: "/standardText",
	BRANDING: "/branding",
	USER_PROFILE: "/userProfile",
	LOGIN: "/login",
	LOGOUT: "/logout",
	LOGOUT_SSO: "/logout/sso",
	CHANGE_ORGANIZATION: "login/update/organization",
	HELP: "/help",
	BASE: "/",
	NOT_FOUND: "/404",
	CHARGESANDCOMMISSIONS: "/chargesAndCommissions",
	CHARGEANDCOMMISSION_ADD: "/addChargeAndCommission",
	NOTIFICATION_SETTINGS: "/notificationSettings",
	TASK_MANAGEMENT: "/tasks",
	PAIRING_PROFILE_ADD: ProductFlowRoutes.PAIRING_PROFILE_ADD,
	PAIRING_PROFILE_EDIT: ProductFlowRoutes.PAIRING_PROFILE_EDIT,
	PAIRING_PROFILE_VIEW: ProductFlowRoutes.PAIRING_PROFILE_VIEW,
};

export default Routes;

const ReportsRoutesFiltered = isEnableNewReport("router") ? [
	ReportsRoutes.DealReports,
	ReportsRoutes.Deals,
	"/new-reports/selection",
] : [];
export const ValidRoutes = [
	Routes.BASE,
	Routes.TASK_MANAGEMENT,
	Routes.BRANDING,
	Routes.USER_PROFILE,
	Routes.NOTIFICATION_SETTINGS,
	ProductFlowRoutes.CURRENCIES,
	ProductFlowRoutes.PARTIES,
	ProductFlowRoutes.CUSTOM_FIELDS,
	ProductFlowRoutes.RULES,
	ProductFlowRoutes.RULES_ADD,
	ProductFlowRoutes.RULES_EDIT,
	ProductFlowRoutes.RULES_EDIT_FORMAT,
	ProductFlowRoutes.PARTY_ADD,
	ProductFlowRoutes.PARTY_VIEW,
	ProductFlowRoutes.PARTY_EDIT,
	ProductFlowRoutes.BANKS,
	ProductFlowRoutes.BANK_ADD,
	ProductFlowRoutes.BANK_EDIT,
	ProductFlowRoutes.BANK_LIMIT,
	ProductFlowRoutes.BANKS_LIMIT_DETAILS,
	ProductFlowRoutes.CUSTOM_FIELDS_ADD,
	ProductFlowRoutes.CUSTOM_FIELDS_ADD_FORMAT,
	ProductFlowRoutes.CUSTOM_FIELDS_EDIT_FORMAT,
	ProductFlowRoutes.CUSTOM_FIELDS_VIEW_FORMAT,
	ProductFlowRoutes.ORGANIZATION_PROFILE,
	ProductFlowRoutes.ORGANIZATION_STRUCTURE,
	ProductFlowRoutes.STANDARD_TEXT,
	ProductFlowRoutes.AUDIT_LOGS,
	ProductFlowRoutes.CHARGESANDCOMMISSIONS,
	ProductFlowRoutes.CHARGEANDCOMMISSION_ADD,
	ProductFlowRoutes.CHARGEANDCOMMISSION_EDIT,
	ProductFlowRoutes.DUPLICATE,
	ProductFlowRoutes.PRODUCT_UNDERTAKING_TYPE,
	ProductFlowRoutes.PRODUCT_STATE_DISCUSSION_NOTIFICATION,
	ProductFlowRoutes.PRODUCT_STATE_MODE,
	ProductFlowRoutes.PRODUCT_STATE_MODE_DRAWING_NUMBER,
	ProductFlowRoutes.PRODUCT_STATE_MODE_DRAWING_NUMBER_INSTALLMENT,
	ProductFlowRoutes.PRODUCT_STATE_MODE_ATTACHMENT,
	ProductFlowRoutes.PRODUCT_STATE_UUID,
	ProductFlowRoutes.PRODUCT_STATE,
	ProductFlowRoutes.PRODUCT,
	ProductFlowRoutes.REPORTS,
	ProductFlowRoutes.EMBEDED_REPORT,
	ProductFlowRoutes.PLAN_SELECTION,
	ProductFlowRoutes.PAIRING_PROFILE_ADD,
	ProductFlowRoutes.PAIRING_PROFILE_EDIT,
	ProductFlowRoutes.PAIRING_PROFILE_VIEW,
	ProductFlowRoutes.PAIR_PROFILES,
	"/doka/poc",
	DokaRoutes.DokaFlow,
	...ReportsRoutesFiltered,
];

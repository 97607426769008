import { StrictMode } from "react";
import { render } from "react-dom";
import { Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import { ConfirmationRouter } from "@surecomp/product-flow/components/ConfirmationRouter";

import Routes from "./routes.const";

const AboutVersions = loadable(() => import("./screens/AboutVersions"), {
	resolveComponent: (component) => component.AboutVersions,
});

const MainApp = loadable(() => import("./MainApp"), {
	resolveComponent: (component) => component.MainApp,
});

function Main() {
	return (
		<StrictMode>
			<ConfirmationRouter>
				<Switch>
					<Route exact path={Routes.APP_ABOUT_JSON}>
						<AboutVersions />
					</Route>
					<Route path="*">
						<MainApp />
					</Route>
				</Switch>
			</ConfirmationRouter>
		</StrictMode>
	);
}

render(<Main />, document.getElementById("root"));
